<template>
  <div class="dashboard-tasks-wrapper">
    <section class="tasks">
      <div class="d-flex align-items-start">
        <div class="depot__filters-wrapper">
          <SearchFilter v-model="searchFilter" />

          <div class="depot__filters">
            <div class="d-flex align-items-center justify-content-between mb-4">
              <h4 class="depot__filters-title">Фильтр</h4>
              <button
                type="button"
                class="depot__filters-reset"
                @mousedown="resetFilters"
              >
                Очистить все
              </button>
            </div>

            <span class="line-separator"></span>

            <h5 class="depot__filters-subtitle">Участки</h5>
            <v-select
              class="depot__select"
              placeholder="Все участки"
              label="title"
              :options="state.areaOptionList"
              :reduce="(item) => item"
              :disabled="state.activeTab.id == 0"
              v-model="areaFilter"
            >
              <template #option="option">
                <span
                  data-bs-toggle="tooltip"
                  data-bs-placement="left"
                  :title="option.title"
                >
                  {{ option.title }}
                </span>
              </template>
              <template v-slot:no-options>Ничего не найдено.</template>
            </v-select>
          </div>
        </div>

        <div class="d-flex flex-column">
          <button
            type="button"
            class="btn btn-gold w-max ms-auto mb-3"
            data-bs-toggle="modal"
            data-bs-target="#create-application-free-task-modal"
          >
            Создать
          </button>

          <slot></slot>
        </div>
      </div>
    </section>
  </div>

  <EditManufactureModal
    id="create-application-free-task-modal"
    title="Создать задачу"
    :manufactureOptionsList="taskState.manufactureOptionsList"
    :productOptionsList="taskState.productOptionsList"
    :stockOptionsList="taskState.stockOptionsList"
    :measurementOptionsList="taskState.measurementOptionsList"
    :apiRequest="createTask"
    :validation="validation"
    @redirectToTask="redirectToTask"
  />
</template>

<script>
import { ref, watch } from "vue";

import useTask from "@/mixins/useTask";

import SearchFilter from "@/components/depot/SearchFilter.vue";
import EditManufactureModal from "@/components/formation/EditManufactureModal.vue";

export default {
  name: "tasks-dashboard-page",
  components: { SearchFilter, EditManufactureModal },
  props: ["state"],
  emits: ["changeFilters"],
  setup(props, { emit }) {
    const areaFilter = ref(null);
    const searchFilter = ref(null);

    watch(
      () => [areaFilter.value, searchFilter.value],
      () => {
        emit("changeFilters", {
          areaFilter: areaFilter.value,
          searchFilter: searchFilter.value,
        });
      }
    );

    const resetFilters = () => {
      areaFilter.value = null;
      searchFilter.value = null;
    };

    const { taskState, redirectToTask, createTask, validation } = useTask();

    return {
      areaFilter,
      searchFilter,
      resetFilters,
      taskState,
      redirectToTask,
      createTask,
      validation,
    };
  },
};
</script>