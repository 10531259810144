<template>
  <FixedColumnTable :isDropdownsWatcher="true">
    <table class="table main-table text-center">
      <thead>
        <tr>
          <th scope="col">№</th>
          <th class="text-start ps-0" scope="col">Заявка</th>
          <th class="ps-0" scope="col">Участок</th>
          <th class="ps-0" scope="col">Статус</th>
          <th class="ps-0" scope="col">Прогресс</th>
          <th scope="col">
            <span class="text-nowrap">Время выполнения</span>
          </th>
          <th class="ps-0" scope="col">
            Фактическое <span class="text-nowrap">время выполнения</span>
          </th>
          <th class="ps-0" scope="col">Изделие</th>
          <th class="ps-0" scope="col">
            <span class="text-nowrap">Кол-во изделий</span>
          </th>
          <th class="ps-0" scope="col">Единица измерения</th>
          <th class="ps-0" scope="col">Исполнитель</th>
          <th class="ps-0" scope="col">
            Действие
            <!-- <button
              type="button"
              class="btn btn-gold h-max ms-auto"
              style="min-width: 146px"
              data-bs-toggle="modal"
              data-bs-target="#create-task-modal"
              @click="$emit('callSetAreaFilter')"
            >
              Создать
            </button> -->
          </th>
          <th class="ps-0" scope="col">Группы</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="task in state.taskList" :key="task.id">
          <td>{{ task.id }}</td>
          <td
            class="text-start ps-0"
            :class="{ 'text-center': !task.deal_title }"
            style="min-width: 300px"
          >
            <router-link
              v-if="task.deal_title"
              class="main-link"
              :to="{
                name: 'formation-page',
                params: { id: parseInt(task.deal_id) },
              }"
              >{{ task.deal_title }}</router-link
            >
            <span v-else>-</span>
          </td>
          <td class="ps-0">
            <span
              class="text-dots-wrap"
              style="max-width: 140px"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              :title="task.area?.title ? task.area?.title : 'Не указан'"
              >{{ task.area?.title ?? "-" }}</span
            >
          </td>
          <td class="ps-0">
            <span
              class="main-circle"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              :title="task.status?.id ? task.status?.title : 'Не указан'"
              :class="
                task.status?.id ? getClassForStatus(task.status?.id) : 'danger'
              "
            ></span>
          </td>
          <td class="ps-0">
            <span
              class="text-nowrap"
              :class="{
                'text-danger': task.progress == 0,
                'text-warning': task.progress > 0 && task.progress < 100,
                'text-success': task.progress == 100,
              }"
              >{{ task.progress ? task.progress + "%" : "0%" }}</span
            >
          </td>
          <td>
            <span class="text-nowrap">{{ task.need_time ?? "-" }}</span>
          </td>
          <td>
            <span class="text-nowrap">{{ task.time ?? "-" }}</span>
          </td>
          <td class="ps-0">
            <div
              class="d-inline-flex align-items-center justify-content-between"
            >
              <span
                class="text-dots-wrap"
                style="max-width: 85px"
                data-bs-toggle="tooltip"
                data-bs-placement="bottom"
                :title="task.product?.title ? task.product?.title : 'Не указан'"
                >{{ task.product?.title ?? "-" }}</span
              >
              <!-- <button
                class="order-tables__edit-material-btn ms-2"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#edit-material-modal"
                @click="
                  $emit('update:productToUpdate', {
                    id: task.product?.id,
                    taskId: task.id,
                  })
                "
              >
                <inline-svg
                  :src="require('@/assets/images/edit-1.svg')"
                  fill="#96AEC1"
                  opacity="0.65"
                />
              </button> -->
            </div>
          </td>
          <td class="ps-0">
            {{ task.quantity ? task.quantity : 0 }}
          </td>
          <td class="ps-0">{{ task.product?.measurement?.title ?? "-" }}</td>
          <td class="ps-0">{{ task.users[0]?.name ?? "-" }}</td>
          <td class="ps-0">
            <router-link
              class="btn btn-dark-blue h-max"
              style="min-width: 146px"
              :to="{
                name: 'plan-task-page',
                params: { id: parseInt(task.id) },
              }"
              >Перейти</router-link
            >
          </td>
          <td class="ps-0">
            <router-link
              :to="{
                name: 'subtask-group-page',
                params: {
                  id: parseInt(task.id),
                  dealid: parseInt(task.deal_id),
                },
              }"
              class="btn btn-gold w-100"
              :disabled="task.subtasks?.length < 1"
            >
              Перейти
            </router-link>
          </td>
        </tr>

        <tr v-show="!state.taskList.length && !isDataLoading">
          <td colspan="8">
            <span class="d-block text-bold my-3">Ничего не найдено!</span>
          </td>
        </tr>
      </tbody>
    </table>
  </FixedColumnTable>
</template>


<script>
import FixedColumnTable from "@/components/ui/FixedColumnTable.vue";

import { getClassForStatus } from "@/helpers/applicationStatus";

export default {
  components: {
    FixedColumnTable,
  },
  props: ["state", "isDataLoading", "productToUpdate"],
  emits: ["update:productToUpdate", "callSetAreaFilter"],
  setup() {
    return { getClassForStatus };
  },
};
</script>