<template>
  <ul class="dashboard__toolbar" :class="{ minimized: !isNavbarMinimized }">
    <div class="container">
      <div class="dashboard__toolbar-inner" style="min-height: 58px">
        <carousel
          class="dashboard__toolbar-carousel w-100"
          :items-to-show="5"
          :itemsToScroll="5"
          snapAlign="start"
        >
          <slide
            class="dashboard__toolbar-item"
            :class="{ active: state.activeTab.id == tab.id }"
            v-for="tab in state.tabs"
            :key="tab.id"
            @click="state.activeTab = tab"
          >
            {{ tab.title }}
          </slide>

          <template #addons="{ slidesCount }">
            <navigation v-if="slidesCount > 5" />
          </template>
        </carousel>
      </div>
    </div>
  </ul>

  <div
    class="dashboard-tasks-wrapper text-center text-bold"
    v-if="state.activeTab.id == 0 && !isPermissionsLoaded"
  >
    Загрузка...
  </div>

  <MainDashboard v-else-if="state.activeTab.id == 0" />

  <section v-else-if="state.activeTab.id == -1" class="admin-dashboard">
    <div
      class="admin-dashboard__system-list"
      style="order: 4; grid-column: span 3"
    >
      <ApplicationsTable :isSupervisor="true" />
    </div>
  </section>

  <TasksPage
    v-else
    :state="state"
    @changeFilters="
      (value) => {
        state.areaFilter = value.areaFilter;
        state.searchFilter = value.searchFilter;
      }
    "
  >
    <TaskTable
      :state="state"
      :isDataLoading="isDataLoading"
      v-model:productToUpdate="productToUpdate"
      @callSetAreaFilter="callSetAreaFilter"
    />
  </TasksPage>

  <TaskModal
    ref="addTaskModalRef"
    id="create-task-modal"
    title="Создание задачи"
    submitBtnText="Добавить"
    :productOptionList="state.productOptionList"
    :areaOptionList="state.areaOptionList"
    :userOptionList="state.userOptionList"
    :manufacture="state.activeTab"
    :refetch="getData"
    :apiRequest="addTask"
    :areaFilter="state.areaFilter"
  />
  <TaskModal
    id="edit-task-modal"
    title="Редактировать задачу"
    submitBtnText="Сохранить"
    :productOptionList="state.productOptionList"
    :areaOptionList="state.areaOptionList"
    :userOptionList="state.userOptionList"
    :manufacture="state.activeTab"
    :entityToUpdate="taskToUpdate"
    :refetch="getData"
    :apiRequest="updateTask"
  />
  <EditMaterialModal
    v-model:productData="productToUpdate"
    :apiRequest="updateTaskMaterial"
  />
</template>

<script>
import { ref, reactive, computed, watch, onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";

import { Carousel, Slide, Navigation } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";

import useLoadingModal from "@/mixins/useLoadingModal";
import { calculateTimeDifference } from "@/helpers/dateTimeUtils";
import { errorAlert } from "@/helpers/alerts";
import moment from "moment";

import {
  getManufactureList,
  getManufactureAreaList,
  getManufactureUserList,
} from "@/api/manufacture";
import {
  getTasksByManufactureId,
  getTasksByDay,
  updateTaskStatus,
  addTask,
  updateTask,
} from "@/api/task";
import { getDealStatuses } from "@/api/deal";
import { updateTaskMaterial } from "@/api/material";
import { getProducts } from "@/api/product";

import MainDashboard from "@/components/dashboard/MainDashboard.vue";
import ApplicationsTable from "@/components/applications/ApplicationsTable.vue";
import TasksPage from "@/components/dashboard/TasksPage.vue";
import TaskModal from "@/components/dashboard/TaskModal.vue";
import TaskTable from "@/components/dashboard/TaskTable.vue";
import EditMaterialModal from "@/components/formation/EditMaterialModal.vue";

export default {
  name: "dashboard-page",
  components: {
    Carousel,
    Slide,
    Navigation,
    MainDashboard,
    ApplicationsTable,
    TasksPage,
    TaskModal,
    TaskTable,
    EditMaterialModal,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const isNavbarMinimized = computed(() => store.getters.isNavbarMinimized);
    const isPermissionsLoaded = computed(
      () => store.getters.isPermissionsLoaded
    );
    const state = reactive({
      activeTab: { id: 0, title: "Главное меню" },
      tabs: [{ id: 0, title: "Главное меню" }],
      taskList: [],
      applicationStatuses: [],
      productOptionList: [],
      areaOptionList: [],
      userOptionList: [],
      areaFilter: null,
      searchFilter: null,
      dayFilter: moment(new Date()).format("YYYY-MM-DD"),
    });
    const taskToUpdate = ref(null);
    const productToUpdate = ref(null);
    const { isDataLoading } = useLoadingModal();
    const addTaskModalRef = ref(null);

    async function getData() {
      if (state.activeTab?.id == -1) return;

      try {
        isDataLoading.value = true;
        state.taskList = await getTasksByManufactureId(
          state.activeTab?.id,
          state.areaFilter?.id,
          state.searchFilter
        );
      } catch {
        errorAlert("Ошибка загрузки, попробуйте еще раз!");
      } finally {
        isDataLoading.value = false;
      }
    }

    watch(
      () => [state.areaFilter, state.searchFilter],
      () => {
        if (state.activeTab.id != 0) getData();
      }
    );

    watch(
      () => state.activeTab,
      () => {
        if (state.activeTab?.id > 0)
          router.replace({
            name: route.name,
            query: {
              manufacture: state.activeTab?.id,
            },
          });
        else
          router.replace({
            name: route.name,
          });

        if (state.activeTab.id == 0) return;

        if (state.areaFilter != null || state.searchFilter != null) {
          state.areaFilter = null;
          state.searchFilter = null;
        } else getData();
      }
    );

    watch(
      () => state.taskList,
      () => {
        taskToUpdate.value = {};
      },
      { deep: true }
    );

    onMounted(() => {
      loadManufacureList();
      // loadDayTasks();
    });

    const loadManufacureList = async () => {
      try {
        isDataLoading.value = true;
        const res = await getManufactureList();
        state.tabs.push(...res);

        if (route.query.manufacture)
          state.activeTab = res.find(
            (item) => item.id == route.query.manufacture
          );

        // if (is_head_of_department.value?.head_of_department) {
        //   state.tabs.push({ id: -1, title: "Заявки" }, ...res);
        // } else {
        //   state.tabs.push(...res);
        // }
      } catch {
        errorAlert("Ошибка загрузки, попробуйте еще раз!");
      } finally {
        isDataLoading.value = false;
      }
    };

    const loadDayTasks = async () => {
      try {
        isDataLoading.value = true;
        state.taskList = await getTasksByDay(state.dayFilter);
      } finally {
        isDataLoading.value = false;
      }
    };

    watch(
      () => state.dayFilter,
      () => {
        loadDayTasks();
      }
    );

    onMounted(async () => {
      try {
        state.applicationStatuses = await getDealStatuses();
      } catch {
        errorAlert("Ошибка загрузки, попробуйте еще раз!");
      }
    });

    onMounted(async () => {
      try {
        state.productOptionList = await getProducts();
      } catch {
        errorAlert("Ошибка загрузки, попробуйте еще раз!");
      }
    });

    watch(
      () => state.activeTab,
      async () => {
        if (state.activeTab.id <= 0) return;

        try {
          state.areaOptionList = await getManufactureAreaList(
            state.activeTab.id
          );
        } catch {
          errorAlert("Ошибка загрузки, попробуйте еще раз!");
        }
      }
    );

    watch(
      () => state.activeTab,
      async () => {
        if (state.activeTab.id <= 0) return;

        try {
          state.userOptionList = await getManufactureUserList(
            state.activeTab.id
          );
        } catch {
          errorAlert("Ошибка загрузки, попробуйте еще раз!");
        }
      }
    );

    const editTaskStatus = async (id, status_id) => {
      try {
        isDataLoading.value = true;
        await updateTaskStatus(id, status_id);
        getData();
      } catch {
        isDataLoading.value = false;
        errorAlert("Ошибка загрузки, попробуйте еще раз!");
      }
    };

    const callSetAreaFilter = () => {
      if (addTaskModalRef.value) {
        addTaskModalRef.value.setAreaFilter();
      }
    };

    return {
      state,
      isPermissionsLoaded,
      isNavbarMinimized,

      taskToUpdate,
      productToUpdate,
      isDataLoading,
      editTaskStatus,
      calculateTimeDifference,
      getData,
      addTask,
      updateTask,
      updateTaskMaterial,
      addTaskModalRef,
      callSetAreaFilter,
    };
  },
};
</script>

<style lang="scss">
.dashboard-tasks-wrapper {
  padding-top: 230px;
}

.task-count {
  &__input {
    border: none;
    font-size: 18px;
    color: var(--main-font-color);
    background-color: var(--main-white);
    padding: 0 5px;
    width: 100%;
    text-align: center;
  }

  &__value {
    color: var(--main-font-color);
    cursor: pointer;
    background-color: transparent;
    border: none;
    padding: 0;
  }

  &__btn {
    color: var(--main-font-color);
    border: none;
    background-color: #fff;
    padding: 0 6px;

    &:disabled {
      cursor: not-allowed;
    }
  }

  &__save-btn {
    color: var(--main-white);
    border: none;

    &:hover,
    &:active {
      color: var(--main-white) !important;
      border: none !important;
    }
  }
}
</style>
