<template>
  <section class="admin-dashboard">
    <div class="admin-dashboard__system-list" style="grid-column: span 3">
      <div v-if="isBitrixActive" class="admin-dashboard__system w-100">
        <div class="d-flex justify-content-between align-items-center mb-3">
          <div class="d-flex flex-column">
            <h4 class="admin-dashboard__title">Система</h4>
            <span class="admin-dashboard__text"> Bitrix24 </span>
          </div>

          <img
            class="admin-dashboard__system-icon"
            src="@/assets/images/bitrix.svg"
            alt="system icon"
          />
        </div>

        <div class="d-flex">
          <button
            type="button"
            class="btn btn-gold btn-sm me-2"
            style="min-width: 109px"
            @click="getBitrixData()"
            :disabled="bitrixLoading"
          >
            <span> Обновить данные</span>

            <div
              v-if="bitrixLoading"
              class="spinner-border spinner-border-sm ms-2"
              role="status"
            >
              <span class="visually-hidden">Загрузка...</span>
            </div>
          </button>
        </div>
      </div>

      <div v-if="isOneCActive" class="admin-dashboard__system w-100">
        <div class="d-flex justify-content-between align-items-center mb-3">
          <div class="d-flex flex-column">
            <h4 class="admin-dashboard__title">Система</h4>
            <span class="admin-dashboard__text"> 1С </span>
          </div>

          <img
            class="admin-dashboard__system-icon"
            src="@/assets/images/1c.svg"
            alt="system icon"
          />
        </div>

        <div class="d-flex">
          <button
            type="button"
            class="btn btn-gold btn-sm me-2"
            style="min-width: 109px"
            @click="getOneCData()"
            :disabled="oneCLoading"
          >
            <span> Обновить данные</span>

            <div
              v-if="oneCLoading"
              class="spinner-border spinner-border-sm ms-2"
              role="status"
            >
              <span class="visually-hidden">Загрузка...</span>
            </div>
          </button>
        </div>
      </div>
    </div>

    <div
      v-if="isShowAppilications"
      class="admin-dashboard__system-list"
      style="grid-column: span 3"
    >
      <PlanTableWrapper :isSupervisor="true" />
    </div>
  </section>
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";

import { successUpdateAlert, errorAlert } from "@/helpers/alerts";
import { getDealFromBitrix } from "@/api/deal";
import { getDataFromOneC } from "@/api/one";

import PlanTableWrapper from "@/components/plan/PlanTableWrapper.vue";

export default {
  name: "admin-dashboard-page",
  components: { PlanTableWrapper },
  setup() {
    const store = useStore();

    const userPermissions = computed(() => store.getters.getUserPermissions);
    const isBitrixActive = computed(
      () => userPermissions.value["24"]?.permissions["24"] ?? false
    );
    const isOneCActive = computed(
      () => userPermissions.value["24"]?.permissions["25"] ?? false
    );
    const isShowAppilications = computed(
      () => userPermissions.value["24"]?.permissions["26"] ?? false
    );

    const bitrixLoading = ref(false);
    const oneCLoading = ref(false);

    const getBitrixData = async () => {
      try {
        bitrixLoading.value = true;
        await getDealFromBitrix();
      } catch {
        errorAlert();
      } finally {
        successUpdateAlert();
        bitrixLoading.value = false;
      }
    };

    const getOneCData = async () => {
      try {
        oneCLoading.value = true;
        await getDataFromOneC();
      } catch {
        errorAlert();
      } finally {
        successUpdateAlert();
        oneCLoading.value = false;
      }
    };

    return {
      isBitrixActive,
      isOneCActive,
      isShowAppilications,

      getBitrixData,
      getOneCData,
      bitrixLoading,
      oneCLoading,
    };
  },
};
</script>