<template>
  <div
    ref="modalRef"
    class="modal fade"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" style="max-width: 834px">
      <form @submit.prevent="onSubmitForm" class="modal-content">
        <div class="modal-header">
          <h5 class="main-form-control__title" id="staticBackdropLabel">
            {{ title }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            :disabled="state.isFormLoading"
          ></button>
        </div>

        <div class="modal-body">
          <div class="create-task px-5 py-3">
            <div class="create-task__inner">
              <input class="visually-hidden" type="text" />

              <div class="d-block">
                <label class="main-form-control__label"> Изделие: </label>

                <v-select
                  label="title"
                  placeholder="Выберите изделие"
                  class="main-form-control__select task-select"
                  :class="{ error: v$.product.$error }"
                  :options="productOptionList"
                  :clearable="false"
                  v-model="state.product"
                  ><template v-slot:no-options
                    >Ничего не найдено.</template
                  ></v-select
                >

                <span class="error-warning" v-if="v$.product.$error"
                  >* Данное поле обязательно</span
                >
              </div>

              <div class="d-block">
                <label class="main-form-control__label">
                  Кол-во изделий:
                </label>

                <input
                  class="main-form-control__input"
                  :class="{ error: v$.quantity.$error }"
                  type="text"
                  placeholder="Введите кол-во изделий"
                  v-model="state.quantity"
                />

                <span class="error-warning" v-if="v$.quantity.$error"
                  >* Данное поле обязательно</span
                >
              </div>

              <div class="d-block">
                <label class="main-form-control__label"> Участок: </label>

                <v-select
                  label="title"
                  placeholder="Выберите участок"
                  class="main-form-control__select task-select"
                  :class="{ error: v$.area.$error }"
                  :options="areaOptionList"
                  :clearable="false"
                  v-model="state.area"
                  ><template v-slot:no-options>Ничего не найдено.</template>
                  <template #selected-option="selected_option">
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      :title="selected_option.title"
                      >{{ selected_option.title }}</span
                    >
                  </template>
                </v-select>

                <span class="error-warning" v-if="v$.area.$error"
                  >* Данное поле обязательно</span
                >
              </div>

              <div class="d-block">
                <label class="main-form-control__label"> Исполнитель: </label>

                <v-select
                  label="name"
                  placeholder="Выберите исполнителя"
                  class="main-form-control__select task-select"
                  :class="{ error: v$.user.$error }"
                  :options="userOptionList"
                  :clearable="false"
                  v-model="state.user"
                  ><template v-slot:no-options
                    >Ничего не найдено.</template
                  ></v-select
                >

                <span class="error-warning" v-if="v$.user.$error"
                  >* Данное поле обязательно</span
                >
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <div class="px-5">
            <button
              type="button"
              class="btn btn-white btn-md"
              :disabled="state.isFormLoading"
              @click="onResetForm"
            >
              Отмена
            </button>
            <button
              type="submit"
              class="btn btn-gold btn-md"
              :disabled="state.isFormLoading"
            >
              <span>{{ submitBtnText }}</span>

              <div
                v-if="state.isFormLoading"
                class="spinner-border spinner-border-sm ms-2"
                role="status"
              >
                <span class="visually-hidden">Загрузка...</span>
              </div>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { reactive, watch } from "vue";

import useModal from "@/mixins/useModal";
import { required, numeric } from "@vuelidate/validators";

export default {
  props: [
    "title",
    "submitBtnText",
    "entityToUpdate",
    "refetch",
    "apiRequest",
    "productOptionList",
    "areaOptionList",
    "userOptionList",
    "areaFilter",
  ],
  emits: ["update:entityToUpdate"],
  setup(props) {
    const state = reactive({
      isFormLoading: false,
      product: null,
      quantity: null,
      area: null,
      user: null,
    });
    const rules = {
      product: { required },
      quantity: { required, numeric, minValue: (item) => item >= 1 },
      area: {},
      user: { required },
    };
    const { modalRef, onSubmitForm, v$, onResetForm } = useModal(props, {
      state,
      rules,
    });

    watch(
      () => props.areaFilter,
      () => {
        setAreaFilter();
      }
    );

    const setAreaFilter = () => {
      state.area = props.areaFilter;
    };

    return {
      modalRef,
      state,
      v$,
      onSubmitForm,
      onResetForm,
      setAreaFilter,
    };
  },
};
</script>

<style lang="scss">
.create-task {
  &__inner {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px 50px;
  }
}

.task-select .vs__selected {
  width: 220px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  line-height: 37.5px;
}
</style>